<template>
<div class="hero-alider-area">
  <div class="hero-slider__container-area">
    <div class="swiper-container hero-slider__container">
      <div class="swiper-wrapper hero-slider__wrapper">
        <swiper :options="swiperOption">
          <!--=======  single slider item  =======-->
          <div class="swiper-slide hero-slider__single-item" :style="{ backgroundImage: `url(${item.image})` }" v-for="item in sliderData" :key="item.id">
            <div class="hero-slider__content-wrapper">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12" style="padding:0px;">
                    <div class="hero-slider__content col-md-3" :class="[item.addClass]">
                      <h5 class="hero-slider__title" style="font-size: 40px;margin-top:15%">
                        {{ item.title }}
                      </h5>
                      <p class="hero-slider__text">{{ item.desc }}</p>
                      <div v-if="item.button">
                        <router-link :to="{ path: item.bpath }" class="hero-slider__btn">{{ item.btext }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--=======  End of single slider item  =======-->
          <div class="ht-swiper-button-prev ht-swiper-button-prev-13 ht-swiper-button-nav">
            <i class="ion-ios-arrow-left"></i>
          </div>
          <div class="ht-swiper-button-next ht-swiper-button-next-13 ht-swiper-button-nav">
            <i class="ion-ios-arrow-forward"></i>
          </div>
        </swiper>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination" style="display:none"></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ["sliderData"],
  data() {
    return {
      swiperOption: {
        speed: 1000,
        delay: 4000,
        loop: true,
        effect: "fade",
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".ht-swiper-button-next",
          prevEl: ".ht-swiper-button-prev"
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.hero-slider__wrapper .swiper-slide-active .hero-slider__content> :nth-child {
  animation-delay: 3s;
}

.hero-slider__content>* {
  animation-duration: 1s;
  animation-delay: 3s;
}

.hero-slider__text {
  font-size: 20px;
  text-align: justify;
  line-height: 1.8;
  color: #ffffff;
  margin-bottom: 10px;
  width: 70%;
}

.hero-slider__title {
  font-size: 70px;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 0% !important;
  margin-bottom: 10px;
}

.hero-slider__btn {
  font-size: 18px;
  margin-bottom: 50px;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
  height: 400px;
  padding: 0px !important;
}

@media only screen and (max-width: 1599px) and (min-width: 1200px),
only screen and (max-width: 1499px) and (min-width: 1200px),
only screen and (max-width: 1199px) and (min-width: 992px) {
  .hero-slider__content {
    height: 400px;
  }
}

@media only screen and (max-width: 720px) {
  .swiper-pagination {
    display: block !important;
  }

  .swiper-pagination-bullet-active {
    background: #0000009e;
  }

  .ht-swiper-button-nav {
    display: none;
  }

  .hero-slider__content {
    height: 400px;
    background-color: #00000063;
  }
}
</style>
