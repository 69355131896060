<template>
  <div class="main-container">
    <Header />

    <HeroOne :sliderData="data.heroOne" />

    <AboutSection addClass="grey-bg" />

    <!--<ServicesGrid />-->

    <div class="swiper-slide latest-project-slider__single-slide">
      <div class="banner-image">
        <img
          style="width: 100%;padding: 0px;"
          src="/assets/img/banners/servicios.jpg"
          class="img-fluid"
          alt="thumbnail"
        />
      </div>      
    </div>

    <Services :services="services.serviceOne" id="services" />

    <div class="swiper-slide latest-project-slider__single-slide">
      <div class="banner-image">
        <img
          style="width: 100%;padding: 0px;"
          src="/assets/img/banners/franquicias.jpg"
          class="img-fluid"
          alt="thumbnail"
        />
      </div>      
    </div>

    <Services
      :services="services.serviceTwo"
      id="franquicias"
      style="background-color:#fff"
    />

    <div class="swiper-slide latest-project-slider__single-slide">
      <div class="banner-image">
        <img
          style="width: 100%;padding: 0px;"
          src="/assets/img/banners/otros-servicios.jpg"
          class="img-fluid"
          alt="thumbnail"
        />
      </div>      
    </div>

    <Services :services="services.serviceTree" id="otros-servicios" />

    <BlogSection addClass="grey-bg" />

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- WhatsApp Bottom -->
    <div id="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios."
        title="Escríbenos por Whastapp"
        data-tap="tooltip"
      >
        <i
          class="fa fa-whatsapp"
          style="color: #ffffff;"
          aria-hidden="true"
        ></i>
      </a>
    </div>

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="100px" right="10px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import HeroOne from "../components/sections/HeroOne";
import ServicesGrid from "@/components/ServicesGrid";
import Services from "@/components/Services";
import Breadcrumb from "../components/Breadcrumb";
import AboutSection from "../components/sections/AboutSection";
import BlogSection from "../components/sections/BlogSection";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

import services from "../data/service.json";
import data from "../data/slider.json";
export default {
  data() {
    return {
      data,
      services,
      items: []
    };
  },
  beforeCreate() {
    if (sessionStorage.getItem("country") === null) {
      sessionStorage.setItem("country", "057-venezuela");
    }
  },
  components: {
    Header,
    HeroOne,
    ServicesGrid,
    Services,
    AboutSection,
    Breadcrumb,
    BlogSection,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  metaInfo: {
    title: "Servicios LikeUGroup – Ecosistema Digital",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "LikeUGroup es un Ecosistema Digital que presta servicios de Marketplace, E-Commerce y transacciones digitales a través de novedosas herramientas tecnológicas."
      },
      {
        name: "keywords",
        content:
          "servicio,digital,comercio,finanzas,mall,comprar,vender,pago,mercado"
      },
      { name: "author", content: "LikeUGroup" },
      { name: "email", content: "servicioslikeugroup@gmail.com" },
      {
        name: "icon",
        content: "<%= BASE_URL %>assets/img/logo/Logo_Bolsita.png"
      }
    ],
    htmlAttrs: {
      lang: "es",
      amp: true
    }
  }
};
</script>

<style lang="scss" scoped>
#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}
@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }
  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
.breadcrumb-area {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 290px;
  position: relative;
}
.breadcrumb-area::before {
  min-height: 0px;
  background-color: #00000000;
}
</style>