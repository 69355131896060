<template>
  <div class="service-grid-item service-grid-item--style2">
    <div class="service-grid-item__image" style="margin-bottom: 0px;">
      <div class="service-grid-item__image-wrapper">
        <router-link :to="{ path: service.link }">
          <img
            :src="service.image"
            class="img-fluid"
            style="min-height: 225px;"
            alt="service thumb"
          />
        </router-link>
      </div>
    </div>
    <div class="service-grid-item__content" style="height: 250px;">
      <h3 class="title">
        <router-link :to="{ path: service.link }">{{
          service.title
        }}</router-link>
      </h3>
      <p class="subtitle" style="text-align: justify;margin-bottom: 0px;">
        {{ service.desc }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service"]
};
</script>

<style lang="css" scoped>
.service-grid-item__image {
  margin-bottom: 60px;
}

.service-grid-item {
  margin-bottom: 60px;
}
</style>