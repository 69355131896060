<template>
  <!--====================  video cta area ====================-->
  <div
    class="video-cta-area section-space--inner--60"
    style="background-color:#fff;"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12">
          <div class="video-cta-content">
            <h4 class="video-cta-content__small-title">
              {{ data.sectionSubTitle }}
            </h4>
            <h3 class="video-cta-content__title">{{ data.sectionTitle }}</h3>
            <p class="video-cta-content__text" style="text-align: justify;">
              {{ data.desc }}
            </p>
            <p class="video-cta-content__text" style="text-align: justify;">
              {{ data.desc2 }}
            </p>
            <!--<router-link to="/" class="btn ht-btn--round">CONTACT US</router-link>-->
          </div>
        </div>
        <div class="col-lg-5 col-md-12">
          <div class="cta-video-image">
            <div class="video-popup">
              <silentbox-single :src="data.youtubeLink">
                <div class="cta-video-image__image">
                  <img
                    :src="data.image"
                    class="img-fluid"
                    title="Ver nuestro Ecosistema Digital"
                    alt="image"
                  />
                </div>
                <div class="cta-video-image__icon">
                  <i class="ion-ios-play"></i>
                </div>
              </silentbox-single>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of video cta area  ====================-->
</template>

<script>
import data from "../../data/about.json";
export default {
  props: ["addClass"],
  data() {
    return {
      data
    };
  }
};
</script>

<style lang="css" scoped>
.video-cta-content__text {
  width: 100%;
}
.cta-video-image {
  margin-left: 10%;
}
</style>