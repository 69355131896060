<template>
<!--====================  blog grid area ====================-->
<div class="blog-grid-area section-space--inner--60" style="background-color:#dfdee3; padding-top: 0px;" :class="addClass">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <!-- section title -->
        <div class="section-title-area text-center">
          <h2 class="section-title section-space--bottom--50">
            {{ services.sectionTitle }}
          </h2>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="blog-grid-wrapper">
          <div class="row center-block">
            <div class="col-lg-4 col-md-6 center-block" v-for="service in services.serviceList" :key="service.id" v-if="service.show && service.country.includes(country)">
              <div>
                <ServiceItem :service="service" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====================  End of blog grid area  ====================-->
</template>

<script>
import ServiceItem from "@/components/ServiceItem";
import data from "../data/service.json";
export default {
  components: {
    ServiceItem
  },
  created() {
    if (sessionStorage.getItem("country") === null) {
      sessionStorage.setItem("country", "057-venezuela");
    } else {
      this.country = sessionStorage.getItem("country");
    }
  },
  props: ["addClass", "services"],
  data() {
    return {
      data,
      country: "057-venezuela"
    };
  }
};
</script>

<style lang="scss">
@media only screen and (max-width: 480px) {
  .service-grid-item--style2 .service-grid-item__content {
    height: 300px !important;
  }
}
</style>
