<template>
	<div class="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
		<div class="blog-post-slider__image section-space--bottom--30">
			<router-link to="/">
				<div class="video-popup">
					<silentbox-single :src="blog.youtubeLink">
						<div class="cta-video-image__image">
							<img :src="blog.image" class="img-fluid" alt="image" />
						</div>
						<div
							class="cta-video-image__icon"
							style="width: 50px; height: 50px; line-height: 50px; left: 45%"
						>
							<i class="ion-ios-play"></i>
						</div>
					</silentbox-single>
				</div>
			</router-link>
		</div>
		<div class="blog-post-slider__content">
			<p class="post-date">{{ blog.date }}</p>
			<h3 class="post-title">
				<router-link to="/">{{ blog.title }}</router-link>
			</h3>
			<p class="post-excerpt" style="text-align: justify">{{ blog.desc }}</p>
			<!--<router-link to="/" class="see-more-link">Leer más</router-link>-->
		</div>
	</div>
</template>

<script>
	export default {
		props: ['blog'],
	}
</script>
