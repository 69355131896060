<template>
  <div
    class="breadcrumb-area bg-img"
    style="height: 290px;background-position: center center;width: -webkit-fill-available;"
    :style="{ 'background-image': 'url(' + url + ')' }"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="page-banner text-center">
            <h1>{{ title }}</h1>
            <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["items", "title", "url"]
};
</script>

<style lang="scss" scoped>
.breadcrumb-area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #00000000;
  opacity: 0.75;
  z-index: -1;
}
</style>